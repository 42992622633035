import React from "react";

import communications from "./images/communications.png";

function CommunicationsCoach() {
  return (
    <div className="styles-1">
      <div className="top-gap-1">
        <div className="wrapper">
          <div className="communications-coach-wrap">
            <div className="communications-coach-1">
              <h1>Communications Coach</h1>
              <p>
                Get ready to heighten your communication and anchoring skills
                with upcoming workshops. Anuj is excited to share his expertise
                through interactive, live sessions designed for students and
                adults.
              </p>
              <p>
                Drawing from over 20 years of experience, these workshops have
                been designed to help you captivate audiences, deliver powerful
                speeches, and express yourself with clarity and confidence.{" "}
              </p>
              <p>
                Register your interest today and prepare to transform your
                communication skills with expert guidance. Workshops launching
                soon!
              </p>
              <div className="communications-coach-1-1">
                <div className="communications-box">
                  <p>
                    To contact me you can email at{" "}
                    <span className="style-2">anuj@ajungirwaua.com</span>
                  </p>
                </div>
                {/*
                <div className="communications-box">
                  <b>YOUR NAME</b>
                  <input type="text" placeholder="" />
                </div>
                <div className="communications-box">
                  <b>EMAIL</b>
                  <input type="text" placeholder="" />
                </div>
                <div className="communications-box">
                  <button>
                    SUBMIT{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="11"
                      viewBox="0 0 12 11"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7 10.854L12 5.85397V5.14697L7 0.146973L6.293 0.853973L10.439 4.99997L0 4.99997V5.99997L10.44 5.99997L6.293 10.146L7 10.854Z"
                        fill="#783A43"
                      />
                    </svg>
                  </button>
                </div>


                 */}
              </div>
            </div>
            <div className="communications-coach-2">
              <br />
              <img src={communications} alt="communications" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunicationsCoach;
