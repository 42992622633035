// text.jsx

import React from "react";
import TableData from "./tbldata"; // Adjust the path as necessary
// import "./Table.css";

const VoiceArtistTable = () => {
  const processData = (data) => {
    return data.flatMap((item) => {
      if (Array.isArray(item.show)) {
        return item.show.map((show) => ({
          ...item,
          show,
        }));
      } else {
        return [item];
      }
    });
  };

  return (
    <div className="table-container">
      {Object.keys(TableData).map((category) => {
        const { columns, data, title } = TableData[category];
        const processedData = processData(data);

        return (
          <div key={category} className="table-section table-style-2">
            <div class="image-box-bg-3">
              <h4>{title}</h4>
              <div className="image-box-wrap">
                <table className="data-table">
                  <thead>
                    <tr>
                      {columns.map((column, index) => (
                        <th key={index}>{column.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {processedData.map((item, index) => (
                      <tr key={index}>
                        {columns.map((column, colIndex) => (
                          <td key={colIndex}>{column.selector(item)}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VoiceArtistTable;
